.login-container {
  flex: 1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container .form {
  width: 90%;
  padding: 20px;
  max-width: 500px;
  border: 1px solid #e1dfdf;
  box-shadow: 0 0 0.3px rgba(0, 0, 0, 0.3);
  background-color: white;
}
