@import url("https://fonts.googleapis.com/css2?family=Forum&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&family=Forum&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primary: #0c3e83;
  --accent: #e8f1fd;
  --gray-1: #c9c9c9;
  --border: #e6e7e9;
  --warn: #ff9800;
  --info: #2196f3;
  --success: #4caf50;
  --danger: #f44336;
}
body {
  background-color: #eef0f2;
}
.raleway,
button,
input {
  font-family: "DM Sans", sans-serif;
}
.montserrat {
  font-family: "Forum", sans-serif;
}
.f100 {
  font-weight: 100;
}
.f200 {
  font-weight: 200;
}
.f300 {
  font-weight: 300;
}
.f400 {
  font-weight: 400;
}
.f500 {
  font-weight: 500;
}
.f600 {
  font-weight: 600;
}
.f700 {
  font-weight: 700;
}
.f800 {
  font-weight: 800;
}
.f900 {
  font-weight: 900;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-baseline {
  justify-content: baseline;
}
.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-end {
  align-items: flex-end;
}
.align-start {
  align-items: flex-start;
}
.flex-column {
  flex-direction: column;
}
.t-primary {
  color: var(--primary);
}
.container {
  width: 100vw;
  min-height: 100vh;
  /* background-color: #fff9f5; */
}
.screen,
.tab-screen .content {
  width: 100%;
  padding: 24px;
}
.screen-flex {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.tab-screen {
  padding: 0px;
}
.tab-screen .content {
  height: calc(100vh - 70px);
  overflow-y: auto;
}
.hover,
.card,
.filter {
  background-color: white;
  cursor: pointer;
}
.card,
.hover:hover {
  -webkit-box-shadow: 10px 10px 46px 0px rgba(212, 212, 212, 1);
  -moz-box-shadow: 10px 10px 46px 0px rgba(212, 212, 212, 1);
  box-shadow: 10px 10px 46px 0px rgba(212, 212, 212, 1);
  background-color: white;
}
.icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  background: none;
  border: none;
  outline: none;
}
.icon div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.icon.fill div {
  background-color: var(--primary);
}
.error {
  color: var(--danger);
  font-size: 0.2em;
}
.splash {
  background-color: white;
  justify-content: center;
  align-items: center;
}
.splash img {
  width: 60%;
  object-fit: contain;
}
.link {
  color: var(--primary);
  text-align: center;
  padding-top: 15px;
  font-size: 0.9em;
}

.link a {
  font-weight: 600;
  cursor: pointer;
  color: var(--primary);
}
.agree {
  display: flex;
  align-items: center;
}
.agree .link {
  text-align: left;
  padding-top: 0px;
  margin-left: 10px;
  font-size: 0.8em;
  flex: 1;
}
span {
  font-size: 0.9em;
  color: #3a3a3c;
}
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.f-2 {
  padding-left: 10px;
}
.t-white {
  color: white;
}
.flex-column {
  flex-direction: column;
}
.justify-end {
  justify-content: flex-end;
}
button,
.card {
  cursor: pointer;
}
.status {
  padding: 2px 4px;
  color: white;
  font-size: 0.8em;
}
.active,
.true {
  background-color: var(--success);
}
.inactive,
.false {
  background-color: var(--danger);
}
.icart img {
  width: 100%;
  margin: 10px 0px;
  border-radius: 4px;
}
.icart {
  padding: 10px;
  margin-bottom: 30px;
}
.icart-card {
  padding: 15px;
}
.icart-map {
  height: 300px;
}
.italic {
  font-style: italic;
}
.border {
  border: 1px solid #edebeb;
}
.filter {
  gap: 15px;
  padding: 10px;
  border: 1px solid var(--border);
  border-radius: 8px;
  margin-bottom: 30px;
}
.filter .input {
  margin-bottom: 0px;
}
.checkedin {
  background-color: var(--info) !important;
  border-color: var(--info) !important;
}
.checkedout,
.suspended {
  background-color: var(--warn) !important;
  border-color: var(--warn) !important;
}
.btn:disabled {
  opacity: 0.4;
}
.btn-rm2 {
  border: none;
  outline: none;
  background: none;
  background-color: var(--success) !important;
  padding: 5px !important;
  color: white !important;
  font-size: 0.8em;
  margin-top: 4px;
}
*:disabled {
  opacity: 0.3;
}
