.dashboard {
  width: 100vw;
  height: 100vh;
}
.home {
  display: flex;
}
.home .body {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.home .body .content {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}
.booking {
  padding: 15px;
  border-radius: 5px;
}
.border {
  border: 1px solid #ddd;
}
.img {
  width: 80px;
  height: 80px;
}
.user span {
  font-size: 0.8em;
}
.user span a {
  color: var(--primary);
}
.user {
  width: 50%;
  border-right: 1px solid #ddd;
}
.info {
  padding-left: 15px;
}
.anchor {
  color: var(--primary);
  background: none;
  outline: none;
  border: none;
  text-decoration: underline;
}
.btable td {
  padding: 10px;
  padding-left: 0px;
  font-size: 0.9em;
}
