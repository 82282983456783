.greetings {
  margin-bottom: 20px;
}
.wallet-card {
  padding: 10px;
  background-color: var(--primary);
  margin-bottom: 10px;
  border-radius: 8px;
  min-height: 130px;
}
.wallet-card.h .icon,
.wallet-card.h .icon div {
  background-color: #cfe4f1;
}
.wallet-card.h {
  margin-bottom: 20px;
}
.home-cards .card {
  width: 47%;
  padding: 10px;
  border-radius: 4px;
  aspect-ratio: 1;
}
.bottom-tab {
  width: 100%;
  background-color: #edd5c2;
  height: 70px;
  position: fixed;
  bottom: 0;
}
.bottom-tab button {
  width: 25%;
  height: 100%;
  border-radius: 0;
  outline: none;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.bottom-tab button span {
  font-size: 0.7em;
  color: #614f4133;
  margin-top: 3px;
}
.bottom-tab button.active span {
  color: #614f41;
}
.option-btn {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  outline: none;
  border: none;
  padding-left: 0px;
  border-bottom: 1px solid var(--border);
}
.option-btn span {
  color: #3a3a3c;
  font-size: 1em;
}
.option-btn svg {
  margin-right: 10px;
}
.option-btn svg:last-child {
  margin-right: 0px;
  margin-left: auto;
}
.stat span {
  display: block;
}
.stat span:last-child {
  font-size: 0.7em;
  margin-top: 5px;
}
.wallet-card.h,
.cardh {
  display: flex;
  flex-direction: column;
}

.wallet-card.h div,
.cardh div {
  margin-top: auto;
}
.wallet-card.h div span,
.cardh div span {
  font-size: 0.6em;
}
.wallet-card.h span {
  color: #d9dcdd;
}
.cardh .icon,
.wallet-card.h.wc .icon {
  background-color: #edd5c2 !important;
}
.cardh .icon div {
  background-color: transparent !important;
}
.cardh h2 {
  color: var(--primary);
  margin-bottom: 5px;
}
.wc {
  background-color: white;
}
.wallet-card.h.wc .icon div {
  background-color: transparent;
}
.btns .btn {
  margin-bottom: 0;
  width: 48%;
  height: 35px;
}
.history {
  margin: 30px 0px;
  padding: 15px 0px;
  border-top: 1px solid var(--border);
}
.history .btn {
  width: 80px;
  height: 25px;
  margin-bottom: 0px;
  border-width: 0px;
  font-size: 0.7em;
}
.ct-full {
  display: flex;
  flex-direction: column;
}
.inv-icon {
  width: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: none;
  outline: none;
  cursor: pointer;
}
.inv-icon div {
  width: 70%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cfe4f1;
  border-radius: 15px;
  margin-bottom: 10px;
}
.inv-icon span {
  font-size: 1em;
}
.input-c {
  padding: 10px 0px;
}
.balance-w span:first-child {
  color: #9c9c9d;
}
.balance-w span {
  font-size: 0.7em;
}
.option-btn .switch {
  margin-left: auto;
}
.add-btn {
  width: 150px !important;
  border-width: 0px !important;
  text-decoration: underline;
}
.options button {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-width: 0px;
  border-bottom: 1px solid var(--border);
}
.verified {
  padding: 15px;
  font-size: 0.9em;
}
.saved-bank {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid var(--primary);
}
.saved-bank h3 {
  font-size: 1em;
}
.options button:disabled {
  opacity: 0.3;
}
.transaction-preview {
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  padding: 5px 0px;
  border-width: 0px;
}
.transaction-preview div {
  flex: 1;
}
.transaction-preview h4 {
  color: black;
}
.transaction-preview .icon {
  margin-right: 10px;
}
.transaction-preview.debit .icon {
  transform: rotate(-90deg);
  background-color: #d7d7d7;
}
.transaction-preview.debit .icon svg path {
  fill: black;
}
.transaction-preview.credit .icon {
  transform: rotate(90deg);
  background-color: #5bc2fd;
}
.transaction-preview .icon div {
  background-color: transparent;
}
.transaction-preview span {
  color: var(--info);
}
.transaction-preview span.amount {
  font-size: 1em;
  color: var(--success);
}
.transaction-preview.pending span.amount {
  color: var(--warn);
}
.card-item {
  padding: 16px 0px;
  border-bottom: 1px solid var(--border);
}
.options img {
  width: 32px;
  height: 32px;
  margin-right: 5px;
}
.btn-danger {
  background-color: var(--danger) !important;
}
.icart-location span {
  margin: 0px 5px;
  font-size: 0.8em;
}
.icart-location button {
  padding: 5px 0px;
  border: 0px;
}
.cards {
  gap: 15px;
  margin-bottom: 30px;
}
.cards .display-card {
  flex: 1;
  border-radius: 4px;
  padding: 10px;
  border-color: var(--accent);
  cursor: pointer;
}
.display-card .svg {
  width: 60px;
  height: 60px;
  background-color: var(--accent);
  border-radius: 8px;
  margin-right: 20px;
}
.display-card span {
  font-size: 0.8em;
}
.display-card svg {
  width: 30px;
}
.box {
   width: 200px;
   background-color: white;
   border: 1px solid #ddd;
}
.apt{
  width: 100%;
  padding: 10px;
}
.boxes{
  flex-wrap: wrap;
  gap: 10px;
}