.btn {
  width: 100%;
  height: 46px;
  border-radius: 4px;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: white;
  cursor: pointer;
  margin-bottom: 30px;
}
.btn.diabled {
  background-color: var(--gray-1) !important;
  border-color: var(--gray-1) !important;
  cursor: not-allowed;
}
.btn.outline {
  color: var(--primary);
  background-color: transparent;
}
.screen-btn {
  margin-top: auto;
  margin-bottom: 0px;
}
.btn-status {
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 5px 10px;
}
