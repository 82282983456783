header {
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #edebeb;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}
.user-info {
  min-width: 150px;
  padding: 0px 10px;
  border: 1px solid #edebeb;
  border-radius: 8px;
  display: flex;
  height: 100%;
  align-items: center;
}
.user-info div:first-child {
  flex: 1;
}
.user-info div:last-child {
  transform: scale(0.7);
}
.user-info span {
  font-size: 0.8em;
}
